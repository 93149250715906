import React, { Component } from 'react';

import '../Components/Eed/EedDashboard.css';
import '../App.css';

import { TEedData } from '../Components/Eed/typedef.EedData';

import DataReader from '../Data/DataReader';

import OverviewLatestData from '../Components/Eed/OverviewLatestData';
import WaterComparisonStackBar from '../Components/Eed/WaterComparisonStackBar';
import SelectableServiceTable from '../Components/Eed/SelectableServiceTable';
import TripleComparisonBarChart from '../Components/Eed/TripleComparisonBarChart';
import WaterComboPieChart from '../Components/Eed/WaterComboPieChart';

import { getPreviousPeriodMonthName } from '../Components/Eed/EedHelper';

const tripleComparisonBarChartPadding = {top: 50, bottom: 50, right: 50, left: 65};

class EedDashboard extends React.Component<any, any>
{
	constructor(props: Component) 
	{
		super(props);
		
		this.state = {
			eedDataLoaded: false,
			yearSelection: new Date("09.01.2021").getFullYear(),
			previousMonth: false
		};
	}

	componentDidMount(): void
	{
		this.setEedDataStates();
	}

	setYearSelectionState(year: number): void
	{ 
		this.setState({yearSelection: year});
	}

	setEedDataStates(): void
	{
		const dataReader = new DataReader();

		dataReader.readlocalCsvData('/data/Testdatensatz.CSV').then(() =>
		{
			const eedCsvData = dataReader.getData();
			const eedData: TEedData = { 
				heating: {
					name: 'Heizung', 
					data: eedCsvData[0].flatMap((reading: any) => reading.SERVICE === 'HEATING' ? reading : []),
					unit: 'kWh'
				}, 
				cooling: {
					name: 'Kühlung',
					data: eedCsvData[0].flatMap((reading: any) => reading.SERVICE === 'COOLING' ? reading : []),
					unit: 'kWh'
				},
				hotWater: {
					name: 'Warmwasser',
					data: eedCsvData[0].flatMap((reading: any) => reading.SERVICE === 'HOT_WATER' ? reading : []),
					unit: 'm³'
				},
				coldWater: {
					name: 'Kaltwasser',
					data: eedCsvData[0].flatMap((reading: any) => reading.SERVICE === 'COLD_WATER' ? reading : []),
					unit: 'm³'
				}
			};
			// const yearRange = new Set(eedCsvData[0].map((p: any) => p.PERI));
			const yearRange = [2019, 2020, 2021];

			return {eedData, yearRange};
		}).then((data) => 
		{
			this.setState({
				eedData: data.eedData,
				yearRange: data.yearRange,
				eedDataLoaded: true,
			});
		});
	}

	renderYearSelectionButtons(): any
	{ 	
		const yearSelectionButtons: Array<JSX.Element> = [];

		this.state.yearRange.forEach((year: any) => 
		{
			yearSelectionButtons.push(
				<button 
					className="yearButton" 
					key={"button-year-" + year}
					onClick={() => this.setYearSelectionState(Number(year))}
					style={(this.state.yearSelection === year) ? {color: "darkgray"} : {}}
				>
					{year}
				</button>);
		});

		return yearSelectionButtons; 
	}

	render(): JSX.Element
	{ 
		if (this.state.eedDataLoaded === false)
		{ 
			return (
				<div>
				Loading...
				</div>
			);
		}
		else 
		{
			return (
				<div className="ContainerEedDashboard">
					<div className="ContainerOverviewLatestData">
						<span className="OverviewLatestDataTitle">Aktuelle Abrechnung <br/> im Vergleich:</span>
						<div className="ContainerOverviewLatestData-Buttons">
							<button className="CompButton" style={(this.state.previousMonth) ? {} : {color: "darkgray"}} onClick={() => this.setState({ previousMonth: false })}>Vorjahresmonat</button>
							<button className="CompButton" style={(this.state.previousMonth) ? {color: "darkgray"} : {}} onClick={() => this.setState({ previousMonth: true })}>Vormonat</button>
						</div>
						<span className="OverviewLatestDataMonth">{getPreviousPeriodMonthName()}</span>
						<OverviewLatestData 
							eedData={this.state.eedData}
							previousMonth={this.state.previousMonth}
						/>
						<div className="ContainerOverviewLatestData-WaterComboPieChart">
							<WaterComboPieChart
								eedData={this.state.eedData}
								previousMonth={this.state.previousMonth}
								title={"Wasserverbrauch"}
							/>
						</div>
					</div>
					<div className="ContainerYearlyDataCharts">
						<div className="ContainerYearSelectionButtons">
							{this.renderYearSelectionButtons()}
						</div>
						<div className="ContainerTripleComparisonBarChart-heating">
							<TripleComparisonBarChart
								eedData={this.state.eedData}
								yearSelection={this.state.yearSelection}
								service={'heating'}
								color={'tomato'}
								markerColor={'#ababab'}
								dependentLabel={`${this.state.eedData.heating.name} (${this.state.eedData.heating.unit})`}
								dependentLabelDy={-25}
								padding={tripleComparisonBarChartPadding}
								width={550}
								legendFontSize={10}
							/>
						</div>
						<div className="ContainerTripleComparisonBarChart-cooling">
							<TripleComparisonBarChart
								eedData={this.state.eedData}
								yearSelection={this.state.yearSelection}
								service={'cooling'}
								color={'blue'}
								markerColor={'#ababab'}
								dependentLabel={`${this.state.eedData.cooling.name} (${this.state.eedData.cooling.unit})`}
								dependentLabelDy={-13}
								padding={tripleComparisonBarChartPadding}
								width={550}
								legendFontSize={10}
							/>
						</div>
						<div className="ContainerWaterComparisonStackBar">
							<WaterComparisonStackBar
								eedData={this.state.eedData}
								yearSelection={this.state.yearSelection}
								dependentLabel={`${this.state.eedData.coldWater.name}/${this.state.eedData.hotWater.name} (${this.state.eedData.hotWater.unit})`}
								legendFontSize={10}
							/>
						</div>
					</div>
					<div className="ContainerSelectableServiceTable">
						<SelectableServiceTable
							eedData={this.state.eedData}
							yearSelection={this.state.yearSelection}
						/>
					</div>
				</div>
			);
		}
	}
}

export default EedDashboard;