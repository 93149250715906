import React, { Component } from 'react';
import { VictoryPie, VictoryLabel, VictoryTooltip, VictoryContainer } from 'victory';
import { VictorySharedEvents } from 'victory-shared-events';
import { getServiceAmountByPeriod, calculatePreviousPeriod } from '../Eed/EedHelper';

class CustomTooltip extends React.Component<any, any>
{
	render (): JSX.Element
	{
		const {color1, color2} = this.props.colors;
		const {children, ...other} = this.props; // eslint-disable-line @typescript-eslint/no-unused-vars
		if (this.props.label === true)
		{
			return (
				<g>
					<VictoryLabel
						{...other}
						text={({ datum }) => datum.x}
						style={{fill: 'white', fontWeight: "bold"}}
					/>
					<VictoryTooltip {...this.props}
						flyoutHeight={30}
						flyoutWidth={70}
						flyoutStyle={{
							stroke: ({ datum }) => datum.x === 'H' ? color1 : color2,
							fill: "white"
						}}
						orientation={this.props.invert ? ({ datum }) => datum.x === 'H' ? 'left' : 'right' : ({ datum }) => datum.x === 'H' ? 'right' : 'left'}
					/>
				</g>
			);
		}
		else
		{
			return (
				<VictoryTooltip {...this.props}
					flyoutHeight={30}
					flyoutWidth={70}
					flyoutStyle={{
						stroke: ({ datum }) => datum.x === 'H' ? color1 : color2,
						fill: "white"
					}}
					orientation={this.props.invert ? ({ datum }) => datum.x === 'H' ? 'left' : 'right' : ({ datum }) => datum.x === 'H' ? 'right' : 'left'}
				/>
			);
		}
	}
}

class WaterComboPieChart extends React.Component<any, any>
{
	constructor(props: Component)
	{ 
		super(props);

		this.state = {
			previousPeriod: calculatePreviousPeriod(),
			comparison: false
		};
	}
	render(): JSX.Element
	{
		const hotWaterPrviousM = parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod, "AMOUNT_PREV_M"));
		const hotWaterPrviousY = parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod, "AMOUNT_PREV_Y"));
		const coldWaterPrviousM = parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod, "AMOUNT_PREV_M"));
		const coldWaterPrviousY = parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod, "AMOUNT_PREV_Y"));

		return (
			<VictoryContainer height={400} width={400}>
				<VictorySharedEvents
					events={[{
						childName: ["pie-main", "pie-comp"],
						target:"data",
						eventHandlers: {
							onMouseOver: () => 
							{
								return {
									childName: ["pie-main", "pie-comp"],
									target: 'labels',
									mutation: () => ({ active: true })
								};
							},
							onMouseOut: () => 
							{
								return {
									childName: ["pie-main", "pie-comp"],
									target: 'labels',
									mutation: () => ({ active: false })
								};
							}
						}
					}]}
				>
					<VictoryPie
						name="pie-main"
						labelComponent={
							<CustomTooltip label={true} invert={true} colors={{color1: 'tomato', color2: 'blue'}}/>
						}
						colorScale={['tomato', 'blue']}
						style={{ labels: { fill: "black" } }}
						innerRadius={100}
						labelRadius={120}
						standalone={false}
						labels={ ({ datum }) => `${datum.y} ${this.props.eedData.hotWater.unit}` }
						data={[
							{ 
								x: 'H',
								y: parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod))
							},
							{ 
								x: 'C',
								y: parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod))
							}
						]}
					/>
					<VictoryPie animate={{duration: 500, easing: "bounce"}}
						name="pie-comp"
						labelComponent={
							<CustomTooltip colors={{color1: 'rgb(255, 224, 218)', color2: 'rgb(204, 204, 255)'}}/>
						}
						colorScale={["rgb(255, 224, 218)", "rgb(204, 204, 255)"]}
						style={{ labels: { fill: "black" } }}
						innerRadius={168}
						labelRadius={160}
						radius={155}
						standalone={false}
						labels={ ({ datum }) => `${datum.y.toFixed(3)} ${this.props.eedData.hotWater.unit}` }
						data={[
							{ 
								x: 'H',
								y: this.props.previousMonth ?
									hotWaterPrviousM :
									hotWaterPrviousY
							},
							{ 
								x: 'C',
								y: this.props.previousMonth ?
									coldWaterPrviousM :
									coldWaterPrviousY
							}
						]}
					/>
					<VictoryLabel
						textAnchor="middle"
						style={{ fontSize: 20 }}
						x={200} y={200}
						text={
							(this.state.comparison ?
								(this.props.previousMonth ?
									coldWaterPrviousM + hotWaterPrviousM :
									coldWaterPrviousY + hotWaterPrviousY
								) :
								(parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod)) + 
									parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod)))).toFixed(3) + " " + this.props.eedData.hotWater.unit 
						}
					/>
					<VictoryLabel
						textAnchor="middle"
						style={{ fontSize: 19 }}
						x={200} y={15}
						text={this.props.title}
					/>
				</VictorySharedEvents>
				<g>
					<rect x="70" y="375" height="10" width="10" style={{fill: 'blue'}}/>
					<rect x="85" y="375" height="10" width="10" style={{fill: 'tomato'}}/>
					<text x="100" y="385">Verbrauch</text>
					
					<rect x="210" y="375" height="10" width="10" style={{fill: "rgb(204, 204, 255)"}}/>
					<rect x="225" y="375" height="10" width="10" style={{fill: "rgb(255, 224, 218)"}}/>
					<text x="240" y="385" className="WaterComboPieChart-Legend" key={this.props.previousMonth ? 'Vormonat' : 'Vorjahresmonat'}>
						{this.props.previousMonth ? 'Vormonat' : 'Vorjahresmonat'}
					</text>
				</g>
			</VictoryContainer>
		);
	}
}

export default WaterComboPieChart;