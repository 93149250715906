import React, { Component } from 'react';
import GenericWidget from '../GenericWidget/GenericWidget';
import { TGenericWidgetDescriptor } from '../GenericWidget/typedef.GenericWidget';
import { Widget } from '../../Data/Model/typedef.Model';
import { getServiceAmountByPeriod, calculatePreviousPeriod, stringToHashCode } from '../Eed/EedHelper';
import { TOverviewTileContainer } from '../OverviewTile/typedef.OverviewTile';

class OverviewLatestData extends React.Component<any, any> 
{
	constructor(props: Component) 
	{
		super(props);

		this.state = {
			previousPeriod: calculatePreviousPeriod(),
		};
	}

	renderOverviewWidgets(): Array<any>
	{ 
		const overviewDesc: Array<TGenericWidgetDescriptor> = [
			{
				widget: Widget.OverviewTileContainer,
				descriptor: {
					overviewTiles: [
						{
							descriptor: {
								title: this.props.eedData.hotWater.name,
								value: parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod)) + 
									" " + this.props.eedData.hotWater.unit,
								valueSmall: this.props.previousMonth ?
									parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod, "AMOUNT_PREV_M")) + " " + this.props.eedData.hotWater.unit :
									parseFloat(getServiceAmountByPeriod(this.props.eedData.hotWater, this.state.previousPeriod, "AMOUNT_PREV_Y")) + " " + this.props.eedData.hotWater.unit
							}
						},
						{
							descriptor: {
								title: this.props.eedData.coldWater.name,
								value: getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod) +
									" " + this.props.eedData.coldWater.unit,
								valueSmall: this.props.previousMonth ?
									parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod, "AMOUNT_PREV_M")) + " " + this.props.eedData.coldWater.unit :
									parseFloat(getServiceAmountByPeriod(this.props.eedData.coldWater, this.state.previousPeriod, "AMOUNT_PREV_Y")) + " " + this.props.eedData.coldWater.unit
							}
						}
					]
				}
			},
			{
				widget: Widget.OverviewTileContainer,
				descriptor: {
					overviewTiles: [
						{
							descriptor: {
								title: this.props.eedData.heating.name,
								value: getServiceAmountByPeriod(this.props.eedData.heating, this.state.previousPeriod) + 
									" " + this.props.eedData.heating.unit,
								valueSmall: this.props.previousMonth ?
									parseFloat(getServiceAmountByPeriod(this.props.eedData.heating, this.state.previousPeriod, "AMOUNT_PREV_M")) + " " + this.props.eedData.heating.unit :
									parseFloat(getServiceAmountByPeriod(this.props.eedData.heating, this.state.previousPeriod, "AMOUNT_PREV_Y")) + " " + this.props.eedData.heating.unit
							}
						},
						{
							descriptor: {
								title: this.props.eedData.cooling.name,
								value: getServiceAmountByPeriod(this.props.eedData.cooling, this.state.previousPeriod) + 
									" " + this.props.eedData.cooling.unit,
								valueSmall: this.props.previousMonth ?
									parseFloat(getServiceAmountByPeriod(this.props.eedData.cooling, this.state.previousPeriod, "AMOUNT_PREV_M")) + " " + this.props.eedData.cooling.unit :
									parseFloat(getServiceAmountByPeriod(this.props.eedData.cooling, this.state.previousPeriod, "AMOUNT_PREV_Y")) + " " + this.props.eedData.cooling.unit
							}
						}
					]
				}
			}
		];

		overviewDesc.forEach(function(widgetDescriptor, i, overviewDesc)
		{
			try 
			{
				const overviewTileContainer = widgetDescriptor.descriptor as TOverviewTileContainer;
				overviewTileContainer.overviewTiles.forEach((tile) => 
				{
					tile.descriptor.value = tile.descriptor.value + 
						(tile.descriptor.valueSmall ? 
							(tile.descriptor.value > tile.descriptor.valueSmall ? " ↗" : 
								(tile.descriptor.value == tile.descriptor.valueSmall ? " →" : " ↘")
							) : '');
				});

				overviewDesc[i].descriptor = overviewTileContainer;
			} 
			catch (error) 
			{
				return;
			}
		});

		const overviewWidgets = overviewDesc.map((widgetItem: TGenericWidgetDescriptor, i: number) =>
		{
			if (widgetItem.hasSeparator)
				widgetItem.hasSeparator = i === 0 || i >= overviewWidgets.length - 1 ? false : true;
			return <GenericWidget key={'widget-' + widgetItem.widget + '-' + stringToHashCode(JSON.stringify(overviewDesc[i]))} descriptor={widgetItem}/>;
		});

		return overviewWidgets;
	}

	render(): JSX.Element
	{ 
		return (
			<div className="ContainerOverviewLatestData-Widgets">
				{this.renderOverviewWidgets()}
			</div>
		);
	}
}

export default OverviewLatestData;