/**
 * Provide funtions to read data from local json or fetched data
 *
 * Singleton with ES6 Classes
 */

import { TModelDescriptor } from './Model/typedef.Model';
import Papa from 'papaparse';

class DataReader 
{

	private static instance: DataReader;
	private _data: any;

	localData: any = {
		titles: {
			header: "Nutzungsart Anzahl"
		},
		dataSets: []
	};
	entry1 = {
		text: "Wohnung",
		percentage: "89.3",
		color: "#c38e75"
	};
	entry2 = {
		text: "Gewerbe",
		percentage: "0.5",
		color: "#ffe5b4"
	};
	entry3 = {
		text: "Parken",
		percentage: "14.0",
		color: "#fcbc86"
	};
	entry4 = {
		text: "Sonstige",
		percentage: "0.7",
		color: "#aa9e88"
	};

	/*      colors  {
				wohnungen : '#c38e75',
				gewerbe : '#ffe5b4',
				parken : '#fcbc86',
				sonstige : '#aa9e88'
			}*/


	constructor() 
	{
		if (!DataReader.instance) 
		{
			this._data = [];
			DataReader.instance = this;
			//  console.log("Datareader on");
		}
		return DataReader.instance;
	}

	/**
	 * Parse Model from base64 encoded url string
	 * @param urlParameters - url containing ?model=xyz string
	 */
	public getModelFromUrl(urlParameters: string): TModelDescriptor | undefined 
	{
		const b64String = this.getUrlParameterFromString(urlParameters, "model") ?? "";
		let sJson;
		try 
		{
			sJson = decodeURIComponent(this.b64DecodeUnicode(b64String));
			return JSON.parse(sJson);
		}
		catch (error) 
		{
			console.log(error);
			return undefined;
		}
	}

	getUrlParameterFromString(sUrl: string, sParam: string): string | null
	{
		sParam = sParam.replace(/[[\]]/g, "\\$&");
		const regex = new RegExp("[?&]" + sParam + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(sUrl);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	/**
	 * Decode Base64 to unicode
	 *
	 * see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
	 * @param str - base64 encoded unicode string
	 */
	b64DecodeUnicode(str: string): string 
	{
		return decodeURIComponent(atob(str).split('').map(function (c) 
		{
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}

	/**
	 * Encode json obj to Base64
	 * @param jObj - json object to encode
	 */
	b64EncodeUnicode(jObj: any): string 
	{
		return btoa(encodeURIComponent(JSON.stringify(jObj)));
	}

	fetchDataFromEndPoint(url?: string): void
	{
		// implement ......
	}

	readLocalJsonData(fileName?: string): void
	{
		this.localData.dataSets.push(this.entry1);
		this.localData.dataSets.push(this.entry2);
		this.localData.dataSets.push(this.entry3);
		this.localData.dataSets.push(this.entry4);

		this._data.push(this.localData);
	}

	getData(): any
	{
		return this._data;
	}

	fetchCsv(filePath: string): Promise<any>
	{
		return fetch(filePath).then(function (response)
		{
			const reader = response.body!.getReader();
			const decoder = new TextDecoder();

			return reader.read().then(function (result) 
			{
				return decoder.decode(result.value);
			});
		});
	}

	async readlocalCsvData(filePath: string): Promise<void>
	{
		const csvData = await this.fetchCsv(filePath);
		const result = Papa.parse(csvData, {header: true});

		this._data.push(result.data);
	}
}

const instance = new DataReader();
Object.freeze(instance);

export default DataReader;
