export enum Color 
{
	primary = '#5a7ca4',
	secondary = '#89ba17',
	tertiary = '#b3b3b3',
	quaternary = '#84a6bd',
	quinary = '#c7d8e3',
	senary = '#e9e9e9',
	septenary = '#f5f5f5',
	octonary = '#000000',
	nonary = '#000000',
	denary = '#000000',
}

export enum ColorOther 
{
	primarySelected = '#003871',
	tertiarySelected = '#707173',
	tooltipFontColor = '#3c3c43'
}

export enum ColorStackingPlan 
{
	none = '#d9d9d9',
	primary = '#003871', 
	secondary = '#5a7ca4',
	tertiary = '#c7d8e3'
}

export enum ColorStackingPlanTest 
{
	none = 'rgb(255,127,127)',
	primary = 'rgb(176,214,255)', 
	secondary = 'rgb(203,223,170)',
	tertiary = 'rgb(255,227,134)'
}