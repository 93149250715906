import React from 'react';
import './OverviewTile.css';
import { TOverviewTileDescriptor } from './typedef.OverviewTile';

class OverviewTile extends React.Component<TOverviewTileDescriptor, any> 
{

	render(): JSX.Element
	{
		return (
			<div className="OverviewTile">
				<div className="OverviewTile-Title">{this.props.title}</div>
				<div className="OverviewTile-Value">{this.props.value}</div>
				<div className="OverviewTile-ValueSmall" key={this.props.valueSmall}>{this.props.valueSmall}</div>
			</div>
		);
	}

}

export default OverviewTile;