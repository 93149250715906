import React from "react";
import { Route, HashRouter } from "react-router-dom";

// import App from './App';
// import TestView from './Views/TestView';

// // diagram views for prototyp
// import Leerstand from './Views/Leerstand';
// import Fluktuation from './Views/Fluktuation';
// import Stammdaten from "./Views/Stammdaten";
// import NutzungsartAnzahl from "./Views/NutzungsartAnzahl";
// import Miete from "./Views/Miete";
// import Forderungen from "./Views/Forderungen";
// import Instandhaltung from "./Views/Instandhaltung";
// import Gewerbevertraege from "./Views/Gewerbevertraege";

// import ViewTester from './Views/ViewTester';

// // generic view that renders according to provided model data
// import GenericView from './Views/GenericView';

// EED Verbrauchsvisualisierung
import EedDashboard from "./Views/EedDashboard";
import EedMobile from "./Views/EedMobile";
class AppRouter extends React.Component 
{
	render(): JSX.Element
	{
		return (
			<HashRouter>
				<div className="router" /* style={{flex: '1 1 auto',  width: 'calc(100% - 38px)'}}*/>
					{/* <Route path="/" exact component={App} /> */}
					{/* <Route path="/test" exact component={ViewTester} />
					<Route path="/testsuite" exact component={TestView} /> */}
					{/* <Route path="/NutzungsartAnzahl" component={NutzungsartAnzahl}></Route> */} {/* <= unwichtig geworden */}
					{/* ab hier wichtig für Prototyp*/}
					{/* <Route path="/Stammdaten" component={Stammdaten}></Route>
					<Route path="/fluktuation" component={Fluktuation}></Route>
					<Route path="/Miete" component={Miete}></Route>
					<Route path="/leerstand" component={Leerstand}></Route>
					<Route path="/Forderungen" component={Forderungen}></Route>
					<Route path="/Instandhaltung" component={Instandhaltung}></Route>
					<Route path="/Gewerbevertraege" component={Gewerbevertraege}></Route>

					<Route path="/GenericView" component={GenericView}></Route> */}

					{/* EED Verbrauchsauswertung */}
					<Route path="/eed-dash" component={EedDashboard}></Route>
					<Route path="/eed-mobile" component={EedMobile}></Route>
				</div>
			</HashRouter>
		);
	}
}

export default AppRouter;