/* tslint:disable */

import React from 'react';
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryLine,
	VictoryScatter,
	VictoryGroup,
	VictoryTooltip,
	VictoryScatterProps,
	VictoryBarProps,
	AnimatePropTypeInterface
} from 'victory';
import './VictoryBarLineCombo.css';
import { Color, ColorOther } from '../../Colors';
import { TBarLineDescriptor, TChartData } from './typedef.VictoryBarLineCombo';

const barColor = Color.primary;
const barColorSelected = ColorOther.primarySelected;
const lineColor = Color.secondary;
const animationDuration = 1500;

type IBarLineComboProps = {
	descriptor: TBarLineDescriptor;
};

type TScatterData = {
	x: number;
	y: number;
	_x: number;
	_y: number;
};

type TBarData = {
	stack: number;
	index: number;
};

type axisStroke = {
	axis: React.CSSProperties;
	tickLabels: React.CSSProperties;
	grid?: React.CSSProperties;
};


/**
 * multiple dependant axis:
 * https://formidable.com/open-source/victory/gallery/multiple-dependent-axes/
 *
 * use normalized tick values
 */
class VictoryBarLineCombo extends React.PureComponent<IBarLineComboProps, any> 
{

	private lineData: TChartData[] = [];
	private barData: TChartData[] = [];
	private maximaLine = 0;
	private maximaBar = 0;
	private lineSuffix = '';
	private barSuffix = '';
	private highlightedScatter: TScatterData | undefined;
	private highlightedBar: TBarData | undefined;
	private noBars: boolean;
	private noLines: boolean;
	private animationID: NodeJS.Timeout | undefined;

	constructor(props: IBarLineComboProps) 
	{
		super(props);

		// create data for the line
		this.lineData = props.descriptor.lines;
		for (const x of this.lineData) 
		{
			this.maximaLine = Math.max(this.maximaLine, ...x.values.map(d => d.y));
		}


		// create data for the bars
		this.barData = props.descriptor.bars;
		for (const x of this.barData) 
		{
			this.maximaBar = Math.max(this.maximaBar, ...x.values.map(d => d.y));
		}

		this.noLines = props.descriptor.lines.length === 0;
		this.noBars = props.descriptor.bars.length === 0;

		this.lineSuffix = props.descriptor.lineSuffix || '';
		this.barSuffix = props.descriptor.barSuffix || '';

		this.state = {
			externalBarMutations: undefined,
			externalScatterMutation: undefined,
			highlightedBar: undefined,
			highlightedScatterPoint: undefined,
			animation: true
		};

		this.removeBarMutation = this.removeBarMutation.bind(this);
		this.removeScatterMutation = this.removeScatterMutation.bind(this);
		this.lineAxisTickFormat = this.lineAxisTickFormat.bind(this);
		this.getAnimationState = this.getAnimationState.bind(this);
	}

	/***************************************
	 *************  LIFECYCLE  *************
	 ***************************************/

	componentDidMount(): void
	{
		// show animation only on startup, disable ist then. Workaround for humping tooltip
		this.animationID = setTimeout((): void=> this.setState({ animation: false }), animationDuration);
	}

	componentWillUnmount(): void
	{
		if (this.animationID)
			clearInterval(this.animationID);
	}

	removeBarMutation(): void
	{
		this.setState({
			externalBarMutations: undefined
		});
	}

	removeScatterMutation(): void
	{
		this.setState({
			externalScatterMutation: undefined
		});
	}

	tooltipMouseOutAndOverEvent(props: { active: boolean }): any
	{
		if (props.active) 
		{
			return { active: true };
		}
	}

	getAnimationState(): AnimatePropTypeInterface | undefined
	{
		if (this.state.animation) 
		{
			return {
				duration: animationDuration,
				onLoad: { duration: animationDuration }
			};
		}
		return undefined;
	}

	renderBars(b: TChartData, stack: number | string): JSX.Element
	{

		const color = (b.color ? b.color : barColor);
		// will be removed through componentDidMount()
		return (
			<VictoryBar
				name={`bar-${stack}`}
				key={`bar-${stack}`}
				data={b.values}
				style={{ data: { fill: color } }}
				standalone={false}
				labels={({ datum }) => `${datum.y}`}
				labelComponent={
					<VictoryTooltip
						active={false}
						pointerLength={0}
						flyoutStyle={{ stroke: color, strokeWidth: 1 }}
						flyoutHeight={30}
						flyoutWidth={60}
						// y={100} // TODO: position tooltip
						style={{ fill: ColorOther.tooltipFontColor }}
					/>}
				animate={this.getAnimationState()}
				y={d => d.y / this.maximaBar}
				externalEventMutations={this.state.externalBarMutations}
				events={[{
					target: 'data',
					eventHandlers: {
						onClick: (evt) => 
						{
							evt.stopPropagation(); // prevent propagation of click event to parent container
							return {
								target: 'data',
								mutation: this.clickHandler.bind(this)
							};
						},
						// override label (tooltip) standart events, we use only click events
						onMouseOver: () => 
						{
							return {
								target: 'labels',
								mutation: this.tooltipMouseOutAndOverEvent.bind(this)
							};
						},
						onMouseOut: () => 
						{
							return {
								target: 'labels',
								mutation: this.tooltipMouseOutAndOverEvent.bind(this)
							};
						}
					}
				}]}
			/>
		);
	}

	onBarClicked(props: any): void
	{
		this.highlightedScatter = undefined;

		const clickedBar: TBarData = {
			index: props.index,
			stack: props.stack ?? (props.datum ? props.datum._stack : undefined)
		};
		const prevBar = this.highlightedBar;

		if (!prevBar || !(clickedBar.stack === prevBar.stack && clickedBar.index === prevBar.index)) 
		{
			// prev highlighted bar does not exist or other bar was clicked -> highlight clicked bar
			this.highlightedBar = clickedBar;

		}
		else 
		{
			// prev highlighted bar was clicked -> unhighlight
			this.highlightedBar = undefined;
		}

		this.setState({
			externalBarMutations: [
				{
					target: ['data', 'labels'],
					eventKey: 'all',
					mutation: (innerProps: any) => this.getBarMutation(innerProps),
					callback: this.removeBarMutation.bind(this)
				}
			],
			externalScatterMutation: [
				{
					target: ['data'],
					eventKey: 'all',
					mutation: (props: any) => this.getScatterPointMutation(props, false), // none highlighted
					callback: this.removeScatterMutation.bind(this)
				}
			]
		});
	}

	/**
	 * manages click events on:
	 *  - parent container
	 *  - scatterPoint
	 *  - bar
	 * @param props
	 */
	clickHandler(props: any): void
	{

		if ((props as VictoryScatterProps).symbol !== undefined) 
		{
			// clicked on scatter point
			this.onScatterPointClicked(props);

		}
		else if ((props as VictoryBarProps).width !== undefined) 
		{
			// clicked on bar
			this.onBarClicked(props);

		}
		else 
		{
			// clicked on container -> unhighlight scatterpoint/bar
			if (this.highlightedScatter) 
			{
				const innerProps = {
					datum: {
						x: this.highlightedScatter.x,
						y: this.highlightedScatter.y,
						_x: this.highlightedScatter._x,
						_y: this.highlightedScatter._y
					}
				};

				this.onScatterPointClicked(innerProps);

			}
			else if (this.highlightedBar) 
			{

				const innerProps = props;
				innerProps.index = this.highlightedBar.index;
				innerProps.stack = this.highlightedBar.stack;

				this.onBarClicked(innerProps);
			}
		}
	}

	onScatterPointClicked(props: any): void
	{
		this.highlightedBar = undefined;

		const clickedScatter: TScatterData = {
			x: props.datum.x,
			y: props.datum.y,
			_x: props.datum._x,
			_y: props.datum._y
		};

		const prevScatter = this.highlightedScatter;

		if (!prevScatter || !(clickedScatter.x === prevScatter.x && clickedScatter.y === prevScatter.y && clickedScatter._x === prevScatter._x && clickedScatter._y === prevScatter._y)) 
		{
			// prev highlighted bar does not exist -> highlight clicked bar
			this.highlightedScatter = clickedScatter;

		}
		else 
		{
			// prev highlighted bar was clicked -> unhighlight
			this.highlightedScatter = undefined;
		}

		this.setState({
			externalBarMutations: [
				{
					target: ['data', 'labels'],
					eventKey: 'all',
					mutation: (innerProps: any) => this.getBarMutation(innerProps, false), // none highlighted
					callback: this.removeBarMutation.bind(this)
				}
			],
			externalScatterMutation: [
				{
					target: ['data'],
					eventKey: 'all',
					mutation: (props: any) => this.getScatterPointMutation(props),
					callback: this.removeScatterMutation.bind(this)
				}
			]
		});
	}

	/**
	 * get styling for bar
	 * @param props
	 */
	getBarMutation(props: any, isHighlighted?: boolean): any
	{

		const hb = this.highlightedBar;
		let _isHighlighted = false;

		if (isHighlighted === undefined)
			_isHighlighted = hb !== undefined && hb.index === props.index && hb.stack === props.datum._stack;
		else
			_isHighlighted = isHighlighted;

		const barColor = _isHighlighted ? barColorSelected : (this.props.descriptor.bars[props.datum._stack - 1]?.color ?? Color.primary);
		return {
			active: _isHighlighted,
			style: {
				data: { fill: barColor },
				fill: barColor,
				width: props.style.width,
				padding: props.style.padding,
				strokeWidth: 0
			}
		};
	}

	/**
	 * get styling for scatter point
	 * @param props
	 */
	getScatterPointMutation(props: any, isHighlighted?: boolean): any
	{

		const hs = this.highlightedScatter;
		let _isHighlighted = false;

		if (isHighlighted === undefined)
			_isHighlighted = hs !== undefined && hs.x === props.datum.x && hs.y === props.datum.y && hs._x === props.datum._x && hs._y === props.datum._y;
		else
			_isHighlighted = isHighlighted;

		return {
			active: _isHighlighted,
			style: {
				fill: _isHighlighted ? '#FFFFFF' : (props.style.fill === '#FFFFFF' ? props.style.stroke : props.style.fill),
				stroke: _isHighlighted ? props.style.fill : props.style.stroke,
				fillOpacity: 1,
				strokeWidth: _isHighlighted ? 3 : 0
			}
		};
	}

	renderLines(): JSX.Element | JSX.Element[]
	{

		const plotted: JSX.Element[] = [];

		this.lineData.forEach((l, i) => 
		{
			const color = (l.color ? l.color : lineColor);
			plotted.push(
				<VictoryLine
					key={i}
					style={{
						data: { stroke: color },
					}}
					standalone={false}
					scale={{ y: 'linear' }}
					animate={this.getAnimationState()}
					data={l.values}
					y={y => this.noBars ? y.y : y.y / this.maximaLine} // use normal data or normalized data
				/>
			);
			plotted.push(
				<VictoryScatter
					key={i}
					labels={({ datum }) => `${datum.y}`}
					labelComponent={
						<VictoryTooltip
							active={false}
							pointerLength={0}
							flyoutStyle={{ stroke: color, strokeWidth: 1 }}
							style={{ fill: ColorOther.tooltipFontColor }}
						/>
					}
					style={{ data: { fill: color } }}
					size={6}
					standalone={false}
					data={l.values}
					y={y => this.noBars ? y.y : y.y / this.maximaLine} // use normal data or normalized data
					animate={this.getAnimationState()}
					externalEventMutations={this.state.externalScatterMutation}
					events={[{
						target: 'data',
						eventHandlers: {
							onClick: (evt) => 
							{
								evt.stopPropagation(); // prevent propagation of click event to parent container
								return [
									{
										target: 'data',
										mutation: this.clickHandler.bind(this)
									}
								];
							},
							// override label (tooltip) standart events, we use only click events
							onMouseOver: () => 
							{
								return {
									target: 'labels',
									mutation: this.tooltipMouseOutAndOverEvent
								};
							},
							onMouseOut: () => 
							{
								return {
									target: 'labels',
									mutation: this.tooltipMouseOutAndOverEvent
								};
							}
						}
					}]}
				/>
			);
		});
		return plotted;
	}

	lineAxisTickFormat(t: number): string | number
	{
		if (this.noBars) 
			return (this.lineSuffix ? `${t} ${this.lineSuffix}` : t);

		// here we have two y-axis, data must be normalized
		const num = t * this.maximaLine;
		const converted = num.toFixed(2);
		return (converted + (this.lineSuffix ? ` ${this.lineSuffix}` : ''));
	}

	render(): JSX.Element 
	{
		let domainPaddingX: number = 15 * this.barData.length;
		let yTickValues: number[] | undefined = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2]; // is used for multiple y axis (normalized data)
		let lineAxisShouldAppearOnSide: 'right' | 'left' | 'bottom' | 'top' | undefined = 'right';
		let yDomain: [number, number] | undefined;

		// only one axis, we don't want to use normalized data for lines
		if (this.noBars) 
		{
			domainPaddingX = 15;
			lineAxisShouldAppearOnSide = 'left';
			yTickValues = undefined; // use not normalized data
			yDomain = [0, this.maximaLine * 1.2]; // use not normalized data
		}

		const lineAxisStroke: axisStroke =
		{
			axis: { stroke: 'transparent' },
			tickLabels: { fontSize: 13, padding: 5, fill: lineColor }
		};
		if (this.noBars) 
		{
			lineAxisStroke.grid = { stroke: 'grey', padding: -100 }; // use grid of line axis
		}

		return (
			<div className='VictoryBarChartLineComboContainer' onClick={this.clickHandler.bind(this)}>
				<div className='VictoryBarChartLineComboContainerInnerChart'>
					<VictoryChart domainPadding={{ x: domainPaddingX }} // add padding to left and right of data inside container
						width={700}
						height={450}>

						{/* x Achse */}
						<VictoryAxis
							tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} // until now, we only have month...
							style={{
								tickLabels: { fontSize: 13, padding: 5 }
							}}
							label='Monate'
							standalone={false}
						/>
						{
							// linke y Achse für bars
							this.barData.length > 0 &&
							<VictoryAxis
								dependentAxis
								fixLabelOverlap
								tickValues={yTickValues}
								standalone={false}
								tickFormat={t => t * this.maximaBar + (this.barSuffix ? ` ${this.barSuffix}` : '')}
								padding={{ right: 20 }}
								style={{
									axis: { stroke: 'transparent' },
									grid: { stroke: 'grey', padding: -100, },
									tickLabels: { fontSize: 13, padding: 5, fill: barColor }
								}}
							/>
						}
						{
							// one bar
							this.barData.length === 1 &&
							this.renderBars(this.barData[0], 0)
						}
						{
							// more then one bar
							this.barData.length > 1 &&
							<VictoryGroup offset={20}>
								{
									this.barData.map((b, i) => this.renderBars(b, i))
								}
							</VictoryGroup>
						}

						{
							/* rechte Achse für lines  */
							this.lineData.length > 0 &&
							<VictoryAxis
								dependentAxis
								orientation={lineAxisShouldAppearOnSide}
								standalone={false}
								tickValues={yTickValues}
								domain={yDomain}
								tickFormat={this.lineAxisTickFormat}
								style={lineAxisStroke}
							/>
						}
						{
							this.renderLines()
						}

					</VictoryChart>
				</div>
				<div className='VictoryBarChartLineComboContainerLegend'>
					{
						this.barData.map((b, i) => 
						{
							return (
								<div key={i} className='VictoryBarChartLineComboContainerLegend-Entry'>
									<div className='VictoryBarChartLineComboContainerLegend-Entry-Color' style={{ backgroundColor: (b.color ? b.color : barColor) }}></div>
									<div className='VictoryBarChartLineComboContainerLegend-Entry-Title'>{b.title}</div>
								</div>
							);
						})
					}
					{
						this.lineData.map((l, i) => 
						{
							return (
								<div key={i} className='VictoryBarChartLineComboContainerLegend-Entry'>
									<div className='VictoryBarChartLineComboContainerLegend-Entry-Color-Line' style={{ backgroundColor: (l.color ? l.color : lineColor) }}></div>
									<div className='VictoryBarChartLineComboContainerLegend-Entry-Title'>{l.title}</div>
								</div>
							);
						})
					}

				</div>
			</div>
		);
	}
}

export default VictoryBarLineCombo;