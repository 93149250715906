import React from 'react';
import {VictoryBar, VictoryChart, VictoryLegend, VictoryScatter} from 'victory';
import { VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { VictoryAxis, VictoryLabel } from 'victory';
// import { addFullYear } from './EedHelper';

class Marker extends React.Component<any, any>
{
	render() 
	{
		const {x, y} = this.props;
		if (this.props.horizontal === false || this.props.horizontal === undefined)
		{
			return (
				<rect x={x - 6} y={y - 2} width={12} height={4} style={{fill: this.props.markerColor}}/>
			);
		}
		else
		{
			return (
				<rect x={x - 2} y={y - 6} height={12} width={4} style={{fill: this.props.markerColor}}/>
			);
		}

	}
}

class TripleComparisonBarChart extends React.Component<any, any>
{
	render(): JSX.Element
	{
		return (
			<div className={'TripleComparisonBarChart' + (this.props.horizontal ? '-horizontal' : '')}>
				<VictoryChart
					height={this.props.height} width={this.props.width}
					padding={this.props.padding}
					domainPadding={{ x: 10, y: [0, 20] }}
					scale={{ x: "time" }}
					containerComponent={
						<VictoryVoronoiContainer 
							voronoiDimension="x"
						/>
					}
				>
					<VictoryLabel 
						textAnchor={"middle"} text={this.props.eedData[this.props.service].name}
						x={this.props.horizontal ? 220 : (this.props.width / 2)} y={10}
						style={[
							{ fontSize: 20 }
						]}
					/>
					<VictoryLegend 
						x={10} y={20}
						orientation="horizontal"
						style={{labels: {fontSize: this.props.legendFontSize }}}
						gutter={20}
						data={[
							{ name: "Verbrauch", symbol: { fill: this.props.color, type: "square" } },
							{ name: "Vorjahr", symbol: { fill: this.props.color, opacity: 0.2, type: "square" } },
							{ name: "Benchmark", symbol: { fill: this.props.markerColor, type: "square" } }
						]}
					/>
					<VictoryBar
						horizontal={this.props.horizontal}
						barWidth={8}
						style={{
							data: { fill: this.props.color }
						}}
						data={this.props.eedData[this.props.service].data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
						x={(d) => Date.parse(d.PERIOD)}
						y={(d) => parseFloat(d.AMOUNT)}
						labels={({ datum }) =>
							`Verbrauch: ${datum.AMOUNT}
							Vorjahr: ${datum.AMOUNT_PREV_Y}
							Benchmark: ${datum.BENCHMARK_VALUE}`}
						labelComponent={
							<VictoryTooltip orientation={this.props.horizontal ? 'bottom' : 'left'} flyoutStyle={{stroke: this.props.color, fill: 'white'}}/>
						}
					/>
					<VictoryBar
						horizontal={this.props.horizontal}
						barWidth={18}
						style={{
							data: { fill: this.props.color, 
								opacity: 0.2 
							}
						}}
						data={this.props.eedData[this.props.service].data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
						x={(d) => Date.parse(d.PERIOD)}
						y={(d) => parseFloat(d.AMOUNT_PREV_Y)}
					/>
					<VictoryScatter
						dataComponent={<Marker markerColor={this.props.markerColor} horizontal={this.props.horizontal}/>}
						data={this.props.eedData[this.props.service].data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
						x={(d) => Date.parse(d.PERIOD)}
						y={(d) => parseFloat(d.BENCHMARK_VALUE)}
					/>
					<VictoryAxis dependentAxis
						label={this.props.dependentLabel}
						axisLabelComponent={<VictoryLabel dy={this.props.dependentLabelDy} />}
					/>
					<VictoryAxis
						label={this.props.independentLabel}
						tickFormat={(date) => date.toLocaleDateString(undefined, {month: "short"})}
					/>
				</VictoryChart>
			</div>
		);
	}

}

export default TripleComparisonBarChart;