import React from 'react';
import { VictoryCommonProps, VictoryLabel, VictoryLabelProps, VictoryTooltip, VictoryTooltipProps } from 'victory';
import StackingPlan, { VBarData } from './StackingPlan';
import './StackingPlanBarLabel.css';

type StackingPlanBarLabelProps = VictoryCommonProps & VictoryLabelProps & VictoryTooltipProps & {
	datum?: VBarData;
	dataSize: number;
	containerHeight: number;
};

type TooltipState = {
	tooltipHeight: number,
	containerHeight: number
};

/**
 * @class StackingPlanBarLabel - renders label inside bar and tooltip on mouse over bar
 */
export class StackingPlanBarLabel extends React.Component<StackingPlanBarLabelProps> 
{
	// propagates default event, so that tooltips will be shown on mouse over label
	static defaultEvents = VictoryTooltip.defaultEvents;

	render(): JSX.Element
	{	
		const datum: VBarData = this.props.datum as VBarData;
		const scale = this.props.scale as any;
		const labelCenterPos = scale.y(((datum as VBarData).y - datum.y0) / 2 + datum.y0);

		const labelProps = {
			...this.props,
			style: {
				fill:  datum.isTerminated ? 'slategrey' : 'black',
				fontSize: StackingPlan.LABEL_FONT_SIZE + 'px',
				paddingTop: 0,
				paddingBottom: 0,
				textAnchor: "middle"
			},
			x: labelCenterPos,
			dx: 0,
			text: (o: any) =>
			{
				// remove label text if it is too wide
				return datum.isTooWide ? '' : 
					datum.labelData.title + '\n' + 
					(datum.labelData.subTitle ? datum.labelData.subTitle + '\n' : '') + 
					datum.labelData.area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + 'm²';
			}
		};

		return (
			<g>
				<VictoryLabel {...labelProps}/>
				<VictoryTooltip
					{...this.props}
					flyoutComponent={ <FlyOut containerHeight={this.props.containerHeight} dataSize={this.props.dataSize}/> }
				/>
			</g>
		);
	}
}

class FlyOut extends React.Component<StackingPlanBarLabelProps, TooltipState>
{

	constructor(props: StackingPlanBarLabelProps)
	{
		super(props);
		this.state = {
			tooltipHeight: 0,
			containerHeight: props.containerHeight
		};
	}

	render(): JSX.Element
	{

		const datum = this.props.datum;
		const tooltipText = datum?.tooltipText;

		const additionalTooltipDataHtml = [];
		// check if tooltip text exists
		if (tooltipText)
		{
			// add horizintal line
			additionalTooltipDataHtml.push(<div className='hline' key={'horizontal-line'}/>);


			let dataIndex = 0;
			for (const textValue of tooltipText.data)
			{
				// add new text line for every entry
				additionalTooltipDataHtml.push(
					<div key={'tooltip-line-' + dataIndex++} className="line">{textValue}</div>
				);
			}

			// add special notes line
			if (tooltipText.notes)
			{
				additionalTooltipDataHtml.push(
					<div key={'notes-container'}>
						<div key={'tooltip-note-header'} className="line notes-header">{'- notes -'}</div>
						<div key={'tooltip-note-line-' + dataIndex++} className="line">{tooltipText.notes}</div>
					</div>
				);
			}
		}

		let posY = this.props.y || 0;
		const posX = 27; // align tooltip left of bars

		if (posY + this.state.tooltipHeight > this.state.containerHeight)
			posY = posY - this.state.tooltipHeight; // move tooltip by tooltip height when larger than container
		
		return (
			<g>
				<foreignObject x={posX} y={posY} width="100%" height={this.state.tooltipHeight} fontSize={StackingPlan.LABEL_FONT_SIZE + 'px'} >
					<div className={'rect'}>
						<div key={'tooltip-line-name'} >{'name: ' + datum?.labelData.title}</div>
						{
							// render if subtitle not empty
							((datum?.labelData.subTitle?.length || 0) > 0) &&
							<div key={'tooltip-line-price'}>{'price: ' + datum?.labelData.subTitle}</div>
						}
						{
							// render if area not 0
							((datum?.labelData.area || 0) > 0) &&
							<div key={'tooltip-line-area'}>{'area: ' + datum?.labelData.area}</div>
						}
						{additionalTooltipDataHtml}
					</div>
				</foreignObject>
			</g>
		);
	}

	private repositionTooltip(): void
	{
		const tooltipElem: HTMLElement| null = document.querySelector('g > foreignObject > .rect');
		const tooltipStyle = getComputedStyle(tooltipElem as HTMLElement);

		const tooltipPadding = parseFloat(tooltipStyle.paddingTop) + parseFloat(tooltipStyle.paddingBottom);
		const tooltipMargin = parseFloat(tooltipStyle.marginTop) + parseFloat(tooltipStyle.marginBottom);
		const tooltipBorder = parseFloat(tooltipStyle.borderTopWidth) + parseFloat(tooltipStyle.borderBottomWidth);

		const tooltipHeight = parseFloat(tooltipStyle.height) + tooltipPadding + tooltipMargin + tooltipBorder;
		
		this.setState({
			tooltipHeight: Math.ceil(tooltipHeight)
		});
		


	}

	componentDidMount(): void
	{
		this.repositionTooltip();
	}
}