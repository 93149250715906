import { TEedService } from './typedef.EedData';

export function getServiceAmountByPeriod(service: TEedService, period: string, kind?: string): string
{
	const reading = service.data.filter((reading: any) => reading.PERIOD === period);
    
	if (kind !== undefined)
	{
		return reading[0][kind];
	}
	else
	{ 
		return reading[0].AMOUNT;
	}
}

export function calculatePreviousPeriod(): string
{	
	const date = new Date("09.01.2021");
	date.setDate(1);
	date.setMonth(date.getMonth() - 1);
	const prevoiusPeriod = String(date.getFullYear()) + "-" + ((date.getMonth() + 1) < 10 ? "0" + String(date.getMonth() + 1) : String(date.getMonth() + 1));
	return prevoiusPeriod;
}

export function getPreviousPeriodMonthName(): string 
{
	const date = new Date("09.01.2021");
	date.setDate(1);
	date.setMonth(date.getMonth() - 1);
	return date.toLocaleDateString('default', { month: 'long' });
}

export function addFullYear(date: Date): Date
{ 	
	date.setFullYear(date.getFullYear() + 1);
	return date;
}

export function stringToHashCode(string: string): number
{
	let hash = 0;
	for (let i = 0; i < string.length; i++) 
	{
		const char = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash;
	}
	return hash;
}