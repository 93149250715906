import React from 'react';
import './StackingPlanLegend.css';
import { TStackingPlanLegendGroupsData, TStackingPlanLegendProps } from './typedef.StackingPlanLegend';

export type TStackingPlanLegendState = {
	headers: string[] ;
	groups: TStackingPlanLegendGroupsData[];
};

class StackingPlanLegend extends React.Component<TStackingPlanLegendProps, TStackingPlanLegendState>
{

	constructor(props: TStackingPlanLegendProps)
	{
		super(props);
		
		this.state = {
			headers: this.props.descriptor.headers,
			groups: this.props.descriptor.groups
		};
		
	}

	render(): JSX.Element
	{
		return (
			<div className="StackingPlanLegend">
				<table>
					<thead>
						<tr>
							{
								this.state.headers.map((colTitle, i) =>
								{
									return <th key={'legendTitle-' + i + '-' + colTitle} className='StackingPlanLegend-Entry-Title' >{colTitle}</th>;
								})
							}
						</tr>
					</thead>
					<tbody>
						{
							this.state.groups.map((groupItem, i) => 
								<tr key={i} style={{ backgroundColor: groupItem.color }}>
									{
										groupItem.labels.map(label =>
										{
											return <th key={'legendLabel-' + i + '-' + label} className='StackingPlanLegend-Entry'>{label}</th>;
										})
									}
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
		);
	}

}

export default StackingPlanLegend;