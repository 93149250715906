import React from 'react';
import { VictoryPie, VictoryTooltip } from 'victory';
import './PieChart.css';
import { Color, ColorOther } from '../../Colors';
import { TPieChartDescriptor, TPieChartProps } from './typedef.PieChart';

class PieChart extends React.Component<TPieChartProps, any> 
{

	constructor (props: TPieChartProps)
	{
		super(props);
		
		this.state = {
			descriptor: props.descriptor
		};
	}

	private activeIndex: number | undefined;

	render(): JSX.Element
	{

		const descriptor = (this.state.descriptor as TPieChartDescriptor);
		const pieData = descriptor.data.map((d, i) => 
		{
			return {
				color: Object.values(Color)[i + 1],
				title: d.title,
				y: d.total
			};
		});
		
		return (
			<div className="PieChartContainer" >
				<div style={{ width: '200px', height: '200px' }}>
					<VictoryPie //Labels sind per CSS unterdrückt
						data={pieData}
						width={200}
						height={200}
						labels={({ datum }) => `${datum.y}`}
						labelComponent={
							<VictoryTooltip
								active={false}
								pointerLength={0}
								flyoutStyle={{ strokeWidth: 1 }}
								style={{ fill: ColorOther.tooltipFontColor }}
							/>
						}
						colorScale={pieData.map(d => d.color)}
						innerRadius={40}
						padding={0}
						padAngle={1}
						animate={{
							duration: 2000
						}}
						style={{ labels: { display: 'none' } }}
						events={[{
							target: "data",
							eventHandlers: {
								onClick: (() => 
								{
									return [{
										target: "labels",
										eventKey: 'all',
										mutation: (props: any) => 
										{
											// console.log(props);
											return null;
										}
									},
									{
										target: "labels",
										mutation: (props: any) => 
										{
											if (props.index === this.activeIndex)
												this.activeIndex = undefined;
											else
											{ 
												this.activeIndex = props.index;
												return { active: true };
											}
										}
									}];
								}).bind(this),
								onMouseOver: () => 
								{
									return {
										target: "labels",
										mutation: (props: any) => 
										{
											if (props.active) 
											{
												return { active: true };
											}
										}
									};
								},
								onMouseOut: () => 
								{
									return {
										target: "labels",
										mutation: (props: any) => 
										{
											if (props.active) 
											{
												return { active: true };
											}
										}
									};
								}
							}
						}]}
					/>
				</div>
				<div className="PieChart-Legend">
					{
						pieData.map((d, i) =>
						{
							return (
								<div key={i} className="PieChart-Legend-Entry">
									<div style={{ backgroundColor: d.color }} className="PieChart-Legend-Entry-Color"></div>
									<div className="PieChart-Legend-Entry-Title">{d.title}</div>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	}
}

export default PieChart;