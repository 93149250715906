import React, { Component } from 'react';
import { TGenericWidgetDescriptor } from '../GenericWidget/typedef.GenericWidget';
import { Widget } from '../../Data/Model/typedef.Model';
import GenericWidget from '../GenericWidget/GenericWidget';
import { TGenericRow } from '../GenericTable/typedef.GenericTable';
import {stringToHashCode } from '../Eed/EedHelper';


class SelectableServiceTable extends React.Component<any, any>
{
	constructor(props: Component)
	{ 
		super(props);

		this.state = {
			kind: 'AMOUNT',
			monthLabels: [
				"Jan",
				"Feb",
				"Mrz",
				"Apr",
				"Mai",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Okt",
				"Nov",
				"Dez"
			]
		};

	}

	getServiceAmount(service: string, year: number, month: number): string
	{
		let serviceAmount: string;
		try 
		{
			serviceAmount = this.props.eedData[service].data.filter((reading: any) => reading.PERI == year && reading.PE == month)[0][this.state.kind];
		}
		catch (error) 
		{
			serviceAmount = '-';
		}
		
		return serviceAmount + " " + this.props.eedData[service].unit;
	}

	getServiceValues(service: string, year: number): Array<string> 
	{
		const serviceReadings = this.props.eedData[service].data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : []);
		
		const serviceValues = ((): Array<string> => 
		{
			const serviceValues: Array<string> = [];
			for (let i = 1; i <= 12; i++)
			{
				try 
				{
					serviceValues.push(serviceReadings.filter((reading: any) => reading.PE == i)[0][this.state.kind] + " " + this.props.eedData[service].unit);
				} 
				catch (error) 
				{
					serviceValues.push('-');
				}
			}
			return serviceValues;
		})();

		return serviceValues;
	}

	renderServiceTable(): any
	{
		const serviceTable: TGenericWidgetDescriptor =
		{
			widget: Widget.GenericTable,
			descriptor: {
				backgroundColor: "lightgray",
				columnLabels: this.state.monthLabels,
				rows: [
					{
						label: this.props.eedData.hotWater.name,
						values: this.getServiceValues('hotWater', this.props.yearSelection),
					},
					{
						label: this.props.eedData.coldWater.name,
						values: this.getServiceValues('coldWater', this.props.yearSelection),
					},
					{
						label: this.props.eedData.heating.name,
						values: this.getServiceValues('heating', this.props.yearSelection),
					},
					{
						label: this.props.eedData.cooling.name,
						values: this.getServiceValues('cooling', this.props.yearSelection)
					},
				]
			}
		};

		return <GenericWidget key={'widget-' + serviceTable.widget + stringToHashCode(JSON.stringify(serviceTable))} descriptor={serviceTable}/>;
	}

	renderTransposedServiceTable(): any
	{
		const serviceTable: TGenericWidgetDescriptor =
		{
			widget: Widget.GenericTable,
			descriptor: {
				backgroundColor: "lightgray",
				columnLabels: [
					this.props.eedData.hotWater.name,
					this.props.eedData.coldWater.name,
					this.props.eedData.heating.name,
					this.props.eedData.cooling.name,
				],
				rows: this.buildTransposedRows()
			}
		};

		return <GenericWidget key={'widget-' + serviceTable.widget + stringToHashCode(JSON.stringify(serviceTable))} descriptor={serviceTable}/>;
	}

	buildTransposedRows(): Array<TGenericRow>
	{
		const rows: Array<TGenericRow> = [];
		for (let i = 1; i <= 12; i++) 
		{
			rows.push(
				{
					label: this.state.monthLabels[i - 1],
					values: [
						this.getServiceAmount("hotWater", this.props.yearSelection, i),
						this.getServiceAmount("coldWater", this.props.yearSelection, i),
						this.getServiceAmount("heating", this.props.yearSelection, i),
						this.getServiceAmount("cooling", this.props.yearSelection, i)
					]
				}
			);
		}

		return rows;
	}


	render(): JSX.Element
	{
		if (this.props.transpose === true) 
		{
			return (
				<div className="SelectableServiceTable-transposed">
					{this.renderTransposedServiceTable()}
				</div>
			);
		}
		else
		{
			return (
				<div className="SelectableServiceTable">
					{this.renderServiceTable()}
				</div>
			);
		}
	}

}

export default SelectableServiceTable;