import React from 'react';
import { Widget } from '../../Data/Model/typedef.Model';
import VictoryBarLineCombo from '../VictoryBarLineChart/VictoryBarLineCombo';
import GenericTable from '../GenericTable/GenericTable';
import { TOverviewTileContainer } from '../OverviewTile/typedef.OverviewTile';
import { TBarLineDescriptor } from '../VictoryBarLineChart/typedef.VictoryBarLineCombo';
import { TGenericTableDescriptor } from '../GenericTable/typedef.GenericTable';
import OverviewTile from '../OverviewTile/OverviewTile';
import { TGenericWidgetProps, TGenericWidgetDescriptor } from './typedef.GenericWidget';
import PieChart from '../PieChart/PieChart';
import { TPieChartDescriptor } from '../PieChart/typedef.PieChart';
import StackingPlan from '../StackingPlan/StackingPlan';
import { TStackingPlanDescriptor } from '../StackingPlan/typedef.StackingPlan';

class GenericWidget extends React.Component<TGenericWidgetProps, any>
{

	constructor(props: TGenericWidgetProps)
	{
		super(props);

		this.state = {
			widget: props.descriptor
		};
	}

	render(): JSX.Element
	{
		const widgetItem: TGenericWidgetDescriptor = this.state.widget;
		const widgetDescriptor = widgetItem.descriptor;
		// const widgetTitle = widgetItem.title;
		const pageHtml = [];

		// FIXME: implement some kind of indexing for widgets (sectionIndex, dataIndex)
		const sectionIndex = 0;
		const dataIndex = 1;

		// add according component html
		switch (widgetItem.widget)
		{

			// PieChart
			case Widget.PieChart:
				pageHtml.push(
					<PieChart key={'pieChart-' + sectionIndex + '-' + dataIndex} descriptor={widgetDescriptor as TPieChartDescriptor}/>
				);
				break;

			// VictoryBarLineCombo
			case Widget.VictoryBarLineCombo:
				pageHtml.push(
					<VictoryBarLineCombo
						key={'victoryBarLineCombo-' + sectionIndex + '-' + dataIndex}
						descriptor={widgetDescriptor as TBarLineDescriptor}
					/>
				);
				break;

			// StackingPlan
			case Widget.StackingPlan:
				pageHtml.push(
					<StackingPlan
						key={'stackingPlan-' + sectionIndex + '-' + dataIndex}
						descriptor={widgetDescriptor as TStackingPlanDescriptor}
					/>
				);
				break;

			// GenericTable
			case Widget.GenericTable:
				pageHtml.push(
					<GenericTable
						key={'genericTable-' + sectionIndex + '-' + dataIndex}
						descriptor={widgetDescriptor as TGenericTableDescriptor}
					/>
				);
				break;

			// OverviewTileContainer
			case Widget.OverviewTileContainer:
				const tileContainerDescriptor = widgetDescriptor as TOverviewTileContainer;

				const nrOfTiles = tileContainerDescriptor.overviewTiles.length;
				for (let tileIndex = 0; tileIndex < nrOfTiles;)
				{
					let className = 'OverviewTilesNum';
					const overviewTilesHtml = [];

					// test if there are 2 more tiles after current -> add OverviewTilesNum3 class and return, if not
					// test if there is  1 more tile  after current -> add OverviewTilesNum2 class and return, if not
					// test if there are 0 more tiles after current -> add OverviewTilesNum1 class
					let i = 3;
					while (i-- > 0)
					{
						if (tileIndex + i < nrOfTiles) 
						{
							className += (i + 1).toString();

							let j = 0;
							while (j <= i) 
							{
								const overviewTile = tileContainerDescriptor.overviewTiles[tileIndex].descriptor;
								overviewTilesHtml.push(
									<OverviewTile key={'overviewTile-' + sectionIndex + '-' + tileIndex++}
										title={overviewTile.title}
										value={overviewTile.value}
										valueSmall={overviewTile.valueSmall}
									/>
								);
								j++;
							}
							i = 0; // return out of while loop
						}
					}

					pageHtml.push(
						<div key={className + '-' + sectionIndex + '-' + tileIndex} className={className} >{overviewTilesHtml}</div>
					);
				}
				break;

			default:
				console.error('no matching component for data.component =', widgetItem.widget);
				break;
		}

		// add spacer between components
		if ((widgetItem.hasSeparator || widgetItem.hasSeparator === undefined) && widgetItem.widget !== Widget.OverviewTileContainer)
		{
			pageHtml.push(
				<div key={'chartSeparator-' + sectionIndex + '-' + dataIndex} className='ChartSeparator' />
			);
		}

		return (
			<div className="GenericWidget">
				{pageHtml}
			</div>
		);
	}

}

export default GenericWidget;