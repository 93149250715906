import React, { Component } from 'react';
import { VictoryChart, VictoryStack, VictoryBar, VictoryGroup, VictoryZoomContainer, VictoryBrushContainer, VictoryLegend, VictoryTooltip } from 'victory';
import { VictoryContainer } from 'victory';
import { VictoryAxis, VictoryLabel } from 'victory';
// import { VictoryZoomContainerProps, VictoryVoronoiContainerProps } from 'victory';

type VictoryDomain = {
	x: [any , any],
	y: [any, any]
};

class CustomHandle extends React.Component<any, any>
{ 
	render(): JSX.Element
	{ 
		return (
			<g>
				<rect {...this.props}
					width={this.props.visualWidth}
					x={this.props.x + (this.props.width / 2)} //offset for aligining on brush edge
					style={{
						fill: "gray",
						opacity: 0.6
					}}
				/>
				<rect {...this.props}
					height={35}
					width={this.props.visualWidth * 1.5}
					y={this.props.y + 18}
					x={
						(this.props.x + (this.props.width / 2)) - //offset for aligining on brush edge
						((this.props.visualWidth * 1.5) / 2) + (this.props.visualWidth / 2) //offset for centering on visual brush edge
					}
					rx={2}
					ry={2}
					style={{fill: "gray"}}
				/>
			</g>
		);
	}
}

class WaterComparisonStackBar extends React.Component<any, any>
{
	legend: JSX.Element =
	<VictoryLegend 
		x={10} y={20}
		orientation="horizontal"
		style={{
			labels: {fontSize: this.props.legendFontSize }
		}}
		gutter={20}
		itemsPerRow={3}
		rowGutter={-12}
		data={[
			{ name: "Verbrauch", symbol: { fill: "tomato", type: "square" } },
			{ name: "Vorjahr", symbol: { fill: "tomato", opacity: 0.2, type: "square" } },
			{ name: "Benchmark", symbol: { fill: "grey", type: "square" } },
			{ symbol: { fill: "blue", type: "square" } },
			{ symbol: { fill: "blue", opacity: 0.2, type: "square" } },
			{ symbol: { fill: "charcoal", type: "square" } },
		]}
		labelComponent={<VictoryLabel dy={5}/>}
	/>;

	constructor(props: Component) 
	{
		super(props);

		// const months = new Set(this.props.eedData.coldWater.data.PE);

		this.state = {
			zoomDomain: { x: [new Date(this.props.yearSelection, 2, 15), new Date(this.props.yearSelection, 7, 15)] }
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: any): void
	{
		if (nextProps.yearSelection !== this.props.yearSelection)
		{
			this.setState({ zoomDomain: { x: [new Date(nextProps.yearSelection, 0, 15), new Date(nextProps.yearSelection, 5, 15)] } });
		}
	}

	handleZoom(domain: VictoryDomain): void
	{
		this.setState({ zoomDomain: domain });
	}

	renderBrushChart(): JSX.Element | undefined
	{
		if (this.props.zoom)
		{ 
			return (
				<VictoryChart
					padding={{ top: 0, left: 50, right: 50, bottom: 80 }}
					domainPadding={15}
					// width={600}
					height={150} 
					scale={{ x: "time" }}
					containerComponent={
						<VictoryBrushContainer
							brushDimension="x"
							brushDomain={this.state.zoomDomain}
							onBrushDomainChange={this.handleZoom.bind(this)}
							allowDraw={false}
							handleComponent={<CustomHandle visualWidth={2}/>}
							handleWidth={15}
						/>
					}
				>
					<VictoryAxis tickFormat={(date) => date.toLocaleDateString(undefined, {month: "short"})}/>
					<VictoryStack>
						<VictoryBar
							data={this.props.eedData.coldWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
							x={(d) => Date.parse(d.PERIOD)}
							y={(d) => parseFloat(d.AMOUNT)}
							barWidth={15}
							style={{
								data: { fill: "blue"
								}
							}}
						/>
						<VictoryBar
							data={this.props.eedData.hotWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
							x={(d) => Date.parse(d.PERIOD)}
							y={(d) => parseFloat(d.AMOUNT)}
							barWidth={15}
							style={{
								data: { fill: "tomato"
								}
							}}
						/>
					</VictoryStack>
					<VictoryLegend 
						x={10} y={100}
						orientation="horizontal"
						style={{
							labels: {fontSize: this.props.legendFontSize }
						}}
						gutter={25}
						itemsPerRow={3}
						rowGutter={-15}
						data={[
							{ name: "Verbrauch", symbol: { fill: "tomato", type: "square" } },
							{ name: "Vorjahr", symbol: { fill: "tomato", opacity: 0.2, type: "square" } },
							{ name: "Benchmark", symbol: { fill: "grey", type: "square" } },
							{ symbol: { fill: "blue", type: "square" } },
							{ symbol: { fill: "blue", opacity: 0.2, type: "square" } },
							{ symbol: { fill: "charcoal", type: "square" } },
						]}
						labelComponent={<VictoryLabel dy={8}/>}
					/>
				</VictoryChart>
			);
		}
	}

	render(): JSX.Element
	{
		const containerComponent = (() =>
		{
			if (this.props.zoom)
			{
				return (
					<VictoryZoomContainer
						zoomDimension="x"
						zoomDomain={this.state.zoomDomain}
						onZoomDomainChange={this.handleZoom.bind(this)}
						disable={true}
					/>
				);
			}
			else
			{
				return (
					<VictoryContainer/>
				);
			}
		})();

		return (
			<div className="WaterComparisonStackBar">
				<VictoryChart
					containerComponent={containerComponent} 
					scale={{ x: "time" }}
					width={this.props.zoom ? 450 : 1100}
				>
					<VictoryLabel textAnchor={"middle"} text={"Wasser"}
						x={this.props.zoom ? 225 : 550} y={10}
						style={[
							{ fontSize: 20 }
						]}
					/>
					{this.props.zoom ? [] : this.legend}
					<VictoryStack>
						<VictoryBar
							data={this.props.eedData.coldWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
							x={(d) => Date.parse(d.PERIOD)}
							y={(d) => parseFloat(d.AMOUNT_PREV_Y)}
							barWidth={40}
							style={{
								data: { fill: "blue", 
									opacity: 0.2
								}
							}}
						/>
						<VictoryBar
							data={this.props.eedData.hotWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
							x={(d) => Date.parse(d.PERIOD)}
							y={(d) => parseFloat(d.AMOUNT_PREV_Y)}
							barWidth={40}
							style={{
								data: { fill: "tomato", 
									opacity: 0.2
								}
							}}
						/>
					</VictoryStack>
					<VictoryGroup offset={20}>
						<VictoryStack>
							<VictoryBar
								data={this.props.eedData.coldWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
								x={(d) => Date.parse(d.PERIOD)}
								y={(d) => parseFloat(d.AMOUNT)}
								barWidth={20}
								style={{
									data: { fill: "blue"
									}
								}}
								labels={({ datum }) => 
									`Verbrauch: ${datum.AMOUNT}
									Vorjahr: ${datum.AMOUNT_PREV_Y}
									Benchmark: ${datum.BENCHMARK_VALUE}`}
								labelComponent={
									<VictoryTooltip
										flyoutStyle={{ stroke: "blue", fill: "white"}}
									/>
								}
							/>
							<VictoryBar 
								data={this.props.eedData.hotWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
								x={(d) => Date.parse(d.PERIOD)}
								y={(d) => parseFloat(d.AMOUNT)}
								barWidth={20}
								style={{
									data: { fill: "tomato"
									}
								}}
								labels={({ datum }) => 
									`Verbrauch: ${datum.AMOUNT}
									Vorjahr: ${datum.AMOUNT_PREV_Y}
									Benchmark: ${datum.BENCHMARK_VALUE}`}
								labelComponent={
									<VictoryTooltip
										flyoutStyle={{ stroke: "tomato", fill: "white"}}
									/>
								}
							/>
						</VictoryStack>
						<VictoryStack>		
							<VictoryBar
								data={this.props.eedData.coldWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
								x={(d) => Date.parse(d.PERIOD)}
								y={(d) => parseFloat(d.BENCHMARK_VALUE)}
								barWidth={8}
								style={{
									data: { fill: "charcoal", 
										opacity: 0.7 
									}
								}}
							/>
							<VictoryBar
								data={this.props.eedData.hotWater.data.flatMap((element: any) => element.PERI == this.props.yearSelection ? element : [])}
								x={(d) => Date.parse(d.PERIOD)}
								y={(d) => parseFloat(d.BENCHMARK_VALUE)}
								barWidth={8}
								style={{
									data: { fill: "grey", 
										opacity: 0.7 
									}
								}}
							/>
						</VictoryStack>
					</VictoryGroup>
					<VictoryAxis dependentAxis
						label={this.props.dependentLabel}
						axisLabelComponent={<VictoryLabel dy={this.props.dependentLabelDy} />}
					/>
					<VictoryAxis
						label={this.props.independentLabel}
						tickFormat={(date) => date.toLocaleDateString(undefined, {month: "short"})}
					/>
				</VictoryChart>
				{this.renderBrushChart()}
			</div>
		);
	}
}

export default WaterComparisonStackBar;