import React from 'react';
import './GenericTable.css';
import { TGenericTableProps } from './typedef.GenericTable';

class GenericTable extends React.Component<TGenericTableProps, any>
{
	render(): JSX.Element
	{
		return (
			<div className="GenericTable" style={{ backgroundColor: this.props.descriptor.backgroundColor || 'transparent' }}>
				<table>
					<tbody style={{ borderCollapse: 'collapse' }}>
						<tr>
							<th></th>
							{
								this.props.descriptor.columnLabels.map((col, i) =>
								{
									return (
										<th key={i}>{col}</th>
									);
								})
							}
						</tr>
						{
							this.props.descriptor.rows.map((row, i) => 
							{
								return (
									<tr key={i}>
										<td className="GenericTable-rowLabel">{row.label}</td>
										{
											row.values.map((val, i) => 
											{
												return (
													<td key={i}>{val}</td>
												);
											})
										}
									</tr>
								);
							})
						}
					</tbody>
				</table>
			</div>
		);
	}
}

export default GenericTable;