import React from 'react';
import './StackingPlan.css';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { VictoryLabel, VictoryTheme, VictoryStyleInterface } from "victory-core";
import { TStackingPlanProps, TStackingPlanFloorData, StackingPlanTooltipUnitData } from './typedef.StackingPlan';
import { ColorStackingPlanTest } from '../../Colors';
import StackingPlanLegend from './StackingPlanLegend';
import { TStackingPlanLegendDescriptor } from './typedef.StackingPlanLegend';
import { StackingPlanBarLabel } from './StackingPlanBarLabel';

export type StackingPlanState = {
	stackingPlanData: VBarData[][];
	legend: TStackingPlanLegendDescriptor;
	style : StackingPlanStyle;
};

type StackingPlanStyle = {
	containerHeight: number;
	chartPadding: {
		top: number,
		bottom: number,
		left: number,
		right: number
	}
	barWidth: number;
	labelOffset: number;
	y0?: number;
	labelCenterPositions?: [];
};

export type VBarData = {
	x: number;
	y: number;
	y0: number;
	color: string;
	isTerminated: boolean;
	isTooWide?: boolean;
	labelData: {
		title: string,
		subTitle: string,
		area: number
	}
	tooltipText?: StackingPlanTooltipUnitData;
};

class StackingPlan extends React.Component<TStackingPlanProps, StackingPlanState>
{
	public static readonly LABEL_FONT_SIZE = 10;

	constructor (props: TStackingPlanProps)
	{
		super(props);

		const stackingPlanData = StackingPlan.prepareData(props.descriptor.data);
		const barWidth = (StackingPlan.LABEL_FONT_SIZE * 3) + 4;
		const barPadding = stackingPlanData.length * 0.05;

		const stackingPlanStyle: StackingPlanStyle = {
			barWidth: barWidth,
			containerHeight: stackingPlanData.length * (barWidth + barPadding) + 80,
			labelOffset: 25,
			chartPadding: {
				top: 50,
				bottom: 30,
				left: 28,
				right: 2
			}
		};

		this.state = {
			stackingPlanData: stackingPlanData,
			style: stackingPlanStyle,
			legend: props.descriptor.legend
		};
		
	}

	/**
	 * @description Removes labels from horizontal bars, when text is wider than bar
	 */
	private hideLargeBarLabels()
	{
		const chartContainer: HTMLElement | null = document.querySelector('.StackingPlanContainer > .VictoryContainer svg');

		if (!chartContainer) return;

		const updatedStackingPlanData: VBarData[][] = this.state.stackingPlanData;

		let didUpdate = false;

		try 
		{
		// get the horizontal stacks (first data.length elements in container)
			Array.from(chartContainer.children as HTMLCollectionOf<HTMLElement>).slice(0, this.state.stackingPlanData.length).some((stack, stackIndex) =>
			{
				const barCount = stack.childElementCount / 2;
				for (let barIndex = 0, labelIndex = barCount; barIndex < barCount; barIndex++, labelIndex++) 
				{
					const barElement = stack.children[barIndex] as HTMLElement;
					const labelContainerElement = stack.children[labelIndex] as HTMLElement;
					const barHeight = barElement.getBoundingClientRect().width;
					const labelContainerWidth = labelContainerElement.getBoundingClientRect().width;

					if (labelContainerWidth > barHeight)
					{
						// text is wider than bar -> hide
						updatedStackingPlanData[stackIndex][barIndex].isTooWide = true;
						didUpdate = true;
					}
				}
			});

			// only set state if actually updated
			if (didUpdate)
			{
				this.setState({
					stackingPlanData: updatedStackingPlanData,
					style: this.state.style
				});
			}
		}
		catch (e)
		{
			console.error(e, '\n\nHTMLElement is possibly being accessed, even though it is not rendered!');
		}
	}

	/**
	 * @description Transforms stacks: TStackingPlanFloorData[] to VBarData[][]
	 * @param floors
	 * @returns VBarData[][]
	 */
	public static prepareData(floors: TStackingPlanFloorData[]): VBarData[][]
	{
		const xyData: VBarData[][] = [];
		floors.forEach((floor, floorIndex) => 
		{
			const vBarData: VBarData[] = [];
			let prevY = 0;
			floor.units.forEach((unit) =>
			{
				let unitColor: string = ColorStackingPlanTest.none;
				if (unit.terminated)
					unitColor = 'url(#shadedPattern)';
				else if (unit.groupIndex)
					unitColor = Object.values(ColorStackingPlanTest)[(unit.groupIndex)];
				
				const unitData: VBarData = {
					x: floorIndex,
					y: unit.area + prevY,
					y0: prevY,
					color: unitColor,
					isTerminated: unit.terminated || false,
					labelData: {
						title: unit.title || '',
						subTitle: unit.subTitle || '',
						area: unit.area
					},
					tooltipText: unit.tooltipUnitData
				};

				prevY = unitData.y;

				vBarData.push(unitData);

			});
			xyData.push(vBarData);
		});

		return xyData;
	}

	private renderBar(data: VBarData[], dataIndex: number)
	{

		const barStyle: VictoryStyleInterface = {
			data: {
				fill: (o: any) =>
				{
					const datum = (o.datum as VBarData);
					return datum.color;
				},
				stroke: ({ active }) => active ? 'white' : 'black', 
				strokeWidth: 0.25
			}
		};

		return (
			<VictoryBar
				key={'stackingBar-' + dataIndex}
				style={barStyle}
				barWidth={this.state.style.barWidth}
				data={data}
				labels={(o: any) => ''}// need to provide empty labels which will be overridden after componentMound (otherwise label svg won't be rendered)
				labelComponent={ <StackingPlanBarLabel containerHeight={this.state.style.containerHeight} dataSize={this.props.descriptor.data.length}/> }
			/>
		);
	}

	componentDidMount(): void
	{
		this.hideLargeBarLabels();
	}

	render(): JSX.Element
	{

		return (
			<div className="StackingPlanContainer">
				{/* shaded pattern */}
				<svg style={{ height: 0 }}>
					<defs>
						<pattern id="shadedPattern"
							width="8" height="10"
							patternUnits="userSpaceOnUse"
							patternTransform="rotate(45 50 50)">
							<line stroke={Object.values(ColorStackingPlanTest)[(0)]} strokeWidth="8px" y2="10" opacity="0.3"/>
						</pattern>
					</defs>
				</svg>
				{/* shaded pattern */}
				<StackingPlanLegend
					key={'stackingPlanLegend'}
					descriptor={this.state.legend as TStackingPlanLegendDescriptor}
				/>
				<VictoryChart
					height={this.state.style.containerHeight}
					horizontal
					theme={VictoryTheme.material}
					padding={this.state.style.chartPadding}
				>
					{this.state.stackingPlanData
						.map((data: VBarData[], floorIndex: number) => this.renderBar(data, floorIndex))
					}
					<VictoryAxis
						orientation='left'
						key={'y-axis'}
						tickCount={this.state.stackingPlanData.length}
						style={{
							axis: { stroke: 'transparent'},
							grid: { stroke: 'transparent'},
							ticks: { stroke: 'transparent', padding: -8},
							tickLabels: { fill: 'black', fontSize: StackingPlan.LABEL_FONT_SIZE },
						}}
						label='Ebenen'
						axisLabelComponent={
							<VictoryLabel y={this.state.style.labelOffset} angle={0}/>
						}
					/>
				</VictoryChart>
			</div>
		);
	}
}

export default StackingPlan;